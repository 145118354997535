[id^="food-"]
  .cover-image
    width: 100%
    aspect-ratio: 1.618 / 1
    .no-image
      position: relative
      height: 100%
      width: 100%
      display: flex
      justify-content: space-evenly
      align-items: center
      background: #eee
      span
        position: absolute
        .text-secondary
          opacity: 0.8
  .materials
    li
      user-select: none
      &.checked
        background-color: rgba(var(--bs-success-rgb), 0.1) !important
        .fa-regular
          color: rgba(var(--bs-success-rgb), 1)
#food-edit
  h1 + .cover-image
    position: relative
    overflow: hidden
    button
      display: none
      position: absolute
      top: -50px
      right: -50px
      background-color: #eee
      transform: rotate(45deg)
      width: 100px
      height: 100px
      i
        position: absolute
        top: 65px
        right: 40px
        transform: rotate(-45deg)
    &:hover
      button
        display: block
#offcanvasVideo
  ul.list-group
    li
      img
        width: 100%
#food-list
  position: fixed
  tr
    > *
      &
        vertical-align: middle
        a
          text-decoration: none
      &:not(&:first-child)
        width: 1px
        white-space: nowrap