.cover-image
  background-size: cover
  background-position: center

.carousel
  margin-bottom: 4rem
  .carousel-item
    height: 32rem
    .cover-image
      height: 100%
      width: 100%
    .carousel-caption
      bottom: 3rem
      z-index: 10
      h1, h1 + p
        text-shadow: 2px  2px 3px #000, -2px 2px 3px #000, 2px -2px 3px #000, -2px -2px 3px #000

.marketing
  p
    margin-right: .75rem
    margin-left: .75rem
  > .row
    > div
      margin-bottom: 1.5rem
      text-align: center
    .cover-image
      display: inline-block
      height: 140px
      width: 140px
  .featurette
    .cover-image
      height: 500px
      width: 500px
    .featurette-divider
      margin: 5rem 0
    .featurette-heading
      letter-spacing: -.05rem

@media (min-width: 40em)
  .carousel-caption p
    margin-bottom: 1.25rem
    font-size: 1.25rem
    line-height: 1.4

  .featurette-heading
    font-size: 50px

@media (min-width: 62em)
  .featurette-heading
    margin-top: 7rem

// ////////////////////////////////////////////////////////////

.circular
  $size: 200px
  $padding: calc($size / 10)
  margin: 0 auto $padding
  height: $size
  width: $size
  position: relative
  .inner, .outer, .circle
    position: absolute
    z-index: 6
    height: 100%
    width: 100%
    border-radius: 100%
  .inner
    top: 50%
    left: 50%
    height: $size - $padding
    width: $size - $padding
    margin: calc(($size - $padding) / -2) 0 0 calc(($size - $padding) / -2)
    background-color: #fff
    border-radius: 100%
    background-size: cover
    background-position: center
  .numb
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 10
    font-size: 20px
    font-weight: 500
    text-shadow: 2px  2px 3px #fff, -2px 2px 3px #fff, 2px -2px 3px #fff, -2px -2px 3px #fff
  .circle
    z-index: 1
    box-shadow: none
    .bar
      position: absolute
      height: 100%
      width: 100%
      background: #eee
      border-radius: 100%
      clip: rect(0px, $size, $size, calc($size / 2))
      .progress
        position: absolute
        height: 100%
        width: 100%
        border-radius: 100%
        clip: rect(0px, calc($size / 2), $size, 0px)
      &.left .progress
        z-index: 1
        transform: rotate(0deg)
      &.right
        z-index: 3
        transform: rotate(180deg)
        .progress
          transform: rotate(0deg)
