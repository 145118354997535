main
    min-height: calc(100vh - 100px)
img
    max-width: 100%
i.pointer
    cursor: pointer
.navbar-brand
    img
        height: 25px
        margin-right: 7px
        vertical-align: text-top